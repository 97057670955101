import React from "react";
import LpDemo from "../../../page-components/LpDemo";

export default function DemoPageFastStart() {
  return (
    <LpDemo
      leadType="lead-gen"
      formSubHeader="Best-in-class restaurant POS. No upfront costs."
      formId="eb08ef0f-e012-4dd7-9efe-7c2a87ca2070"
      useShortForm
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
      withLineOfBusinessOption
      toggleVisibilityMap={[
        {
          changeOnKey: "industry",
          changeOnValue: "Other",
          togglesItem: "business_type",
        },
      ]}
    />
  );
}
